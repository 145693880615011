<template>
  <el-header :height="6" class="header">
    <el-row>
      <el-col :xl="1" :lg="1" :md="2" :sm="2" :xs="3" class="float-right">
        <theme-button/>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
          <el-image :src="require('@/assets/images/logo-color.png')" class="header-icon"/>
      </el-col>
      <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <h1>360&deg; Integration Consultants</h1>
      </el-col>
    </el-row>
  </el-header>
  <el-row>
    <el-col>
      <el-menu
          class="el-menu-demo menu"
          mode="horizontal"
          text-color="text-secondary-color"
          active-text-color="#FFD04B"
      >
        <el-menu-item>
          <el-link href="/" class="menu-item">Home</el-link>
        </el-menu-item>
        <el-menu-item>
          <el-link href="/about" class="menu-item">About Us</el-link>
        </el-menu-item>
        <el-menu-item>
          <el-link href="/contact" class="menu-item">Contact</el-link>
        </el-menu-item>
        <el-menu-item class="menu-item">
          Ring us on:&nbsp;<el-link href="tel:+61279083860" class="menu-item">+61 (2) 7908 3860</el-link>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import ThemeButton from "@/components/ThemeButton";

export default {
  components: {
    ThemeButton,
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: var(--header-color-backgroud);
  padding-bottom: 1rem;
}

.float-right {
  margin-left: auto;
}

.header-icon {
  width: 100%;
  max-width: 30rem;
  height: auto;
}
.menu {
  background-color: var(--menu-color-backgroud);
  border: 0;
}
h1 {
  margin-left: 20px;
  margin-top: 7rem;
  @media (scrern and max-width: 1199px) {
    margin: auto;
  }
}
.menu-item {
  font-size: 1.25rem;
}
</style>
