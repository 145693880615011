<template>
  <el-footer :height="5" class="footer">
    <el-row justify="center">
      <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24" class="menu-col">
        <el-row justify="center">
          <el-col :span="24">
            <el-menu mode="horizontal" text-color="--text-primary-color" class="menu">
              <el-menu-item>
                <el-link href="/">Home</el-link>
              </el-menu-item>
              <el-menu-item>
                <el-link href="/about">About Us</el-link>
              </el-menu-item>
              <el-menu-item>
                <el-link href="/contact">Contact</el-link>
              </el-menu-item>
              <el-menu-item>
                Ring us on:&nbsp;<el-link href="tel:+61279083860">+61 (2) 7908 3860</el-link>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24" class="brand-col">
        <el-row justify="center">
          <el-col :span="8">
            <el-image :src="require('@/assets/images/logo-color.png')" alt="API/integration consultancy Logo" class="logo" />
          </el-col>
        </el-row>
        <el-row justify="center">
          <el-col :span="18">
            <p>&copy; 2023 Naala Pty Ltd, all rights reserved</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-footer>
</template>

<script>
export default {}
</script>

<style lang="scss">
.footer {
  background-color: var(--footer-color-backgroud);
  //width: 100%;
  .brand-col {
    padding-top: 2rem;
  }
  .menu-col {
    padding-bottom: 2rem;
  }
  .logo {
    width: 10rem;
    height: auto;
  }
  .menu {
    background-color: var(--footer-color-backgroud);
    color: var(--text-secondary-color);
    border: 0;
  }
}
</style>
