<template>
  <input @change="toggleTheme" id="checkbox" type="checkbox" class="switch-checkbox"/>
  <label for="checkbox" class="switch-label">
    <span>🌙</span>
    <span>☀️</span>
    <div class="switch-toggle" :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"></div>
  </label>
</template>

<script>
export default {
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "light-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
          "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}

.switch-label {
  align-items: center;
  background: var(--header-color-backgroud);
  border: calc(var(--theme-button-element-size) * 0.025) solid var(--theme-button-accent-color);
  border-radius: var(--theme-button-element-size);
  cursor: pointer;
  display: flex;
  font-size: calc(var(--theme-button-element-size) * 0.3);
  height: calc(var(--theme-button-element-size) * 0.35);
  position: relative;
  padding: calc(var(--theme-button-element-size) * 0.1);
  transition: background 0.5s ease;
  justify-content: space-between;
  width: var(--theme-button-element-size);
  z-index: 1;
}

.switch-toggle {
  position: absolute;
  background-color: var(--theme-button-background-color);
  border-radius: 50%;
  top: calc(var(--theme-button-element-size) * 0.07);
  left: calc(var(--theme-button-element-size) * 0.07);
  height: calc(var(--theme-button-element-size) * 0.4);
  width: calc(var(--theme-button-element-size) * 0.4);
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
}

.switch-toggle-checked {
  transform: translateX(calc(var(--theme-button-element-size) * 0.6)) !important;
}
</style>
