<template>
  <div class="theme-container">
    <header-region/>
    <el-main>
      <bread-crumb></bread-crumb>
      <RouterView></RouterView>
    </el-main>
    <footer-region/>
  </div>
</template>

<script>
import HeaderRegion from "@/components/HeaderRegion";
import FooterRegion from "@/components/FooterRegion";
import router from "@/router";
import BreadCrumb from "@/components/BreadCrumb";

const defaultTitle = "API/integration"

router.beforeEach((to, from, next) => {
  document.title = to.name ? (defaultTitle + " | " + to.name) : defaultTitle
  next()
})

export default {
  components: {
    HeaderRegion,
    BreadCrumb,
    FooterRegion,
  },
};
</script>

<style lang="scss">
html, body {
  padding: 14px;
  margin: 0;
}

/* Define styles for the default root window element */
:root {
  --theme-button-element-size: 4rem;
  --theme-button-background-color: #398278;
  --theme-button-accent-color: #CACACA;

  --text-primary-color: #000;
  --text-secondary-color: #398278;

  --page-color-backgroud: #FAFAFA;
  --header-color-backgroud: #f4ECCC;
  --menu-color-backgroud: #398278;
  --footer-color-backgroud: #f4ECCC;

  --card-color-backgroud: #f4ECCC;
  --card-color-border: #CACACA;
  --tick-color: #000;
  --anchor-color: #000;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --theme-button-background-color: #121212;
  --theme-button-accent-color: #AAA;

  --text-primary-color: #BFA9A5;
  --text-secondary-color: #93827F;

  --page-color-backgroud: #444;
  --header-color-backgroud: #398278;
  --menu-color-backgroud: #BFA9A5;
  --footer-color-backgroud: #000;

  --card-color-backgroud: #2F2F2F;
  --card-color-border: #2F2F2F;
  --tick-color: #398278;
  --anchor-color: #BFA9A5;
}

h1, h2, h3, h4 {
  color: var(--text-primary-color);
}
p, li {
  color: var(--text-secondary-color);
}
a.link {
  color: var(--anchor-color);
}
.el-sub-menu {
  color: var(--text-primary-color);
}

.theme-container {
  background-color: var(--page-color-backgroud);
  //width: 100vw;
  margin: 0;

  /* Small devices such as large phones (640px and up) */
  @media only screen and (min-width: 40em) {
    margin: 0 20px;
  }

  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 48em) {
    margin: 0 50px;
  }

  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    margin: 0 100px;
  }

  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 80em) {
    margin: 0 200px;
  }
}
</style>
