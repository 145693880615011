import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import Router from '@/router'
import VueGtag from 'vue-gtag'

const gtag = 'G-E0ZJPJ0S08'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
if (process.env.NODE_ENV === "production") {
    app.use(VueGtag, {
        config: {
            config: { id: gtag }
        }
    })
}
app.use(ElementPlus)
    .use(Router)
    .mount('#app')
