<template>
  <el-breadcrumb separator="|">
    <el-breadcrumb-item
        v-for="crumb in this.crumbs"
        :key="crumb.name"
        :to="crumb.to"
        class="bread-crumb">
      {{ crumb.name }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  computed: {
    crumbs: function () {
      let breadcrumb = [];
      if (this.$route.name === 'Home') {
        return breadcrumb
      } else {
        breadcrumb.push({
          path: '/',
          to: '/',
          name: 'Home',
        })
        breadcrumb.push(this.$route)
      }
      return breadcrumb;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
