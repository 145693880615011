/* eslint-disable */
const paths = [
    {
        path: '/',
        name: 'Home',
        components: {
            default: () => import('@/pages/HomePage'),
        }
    }, {
        path: '/about',
        name: 'About',
        components: {
            default: () => import('@/pages/AboutPage'),
        }
    }, {
        path: '/contact',
        name: 'Contact',
        components: {
            default: () => import('@/pages/ContactPage'),
        },
    }, {
        path: '/:catchAll(.*)*',
        name: "Not Found",
        components: {
            default: () => import('@/pages/PageNotFound'),
        },
    },
]

module.exports = {paths};
